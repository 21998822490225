<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  mounted() {},
  watch: {
    $route(n) {
      if (n.meta && n.meta.isBackTop) return
      document.body.scrollTop = document.documentElement.scrollTop = 0
    }
  }
}
</script>

<style lang="less">
#app {
  height: 100vh;
}
</style>

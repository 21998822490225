import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from "./router"
import CU from "@common/util"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // parseQuery: query => {
  //   if (query) {
  //     return query
  //   };
  //   return ''
  // },
  // stringifyQuery: obj => {
  //   if (obj && typeof obj == 'string') {
  //     return '?' + obj;
  //   }
  //   for (let key in obj) {
  //     return obj
  //   };
  //   return ''
  // }
})

router.beforeEach(judge)

function judge(to, from, next) {
  if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
    app(to, from, next)
  } else {
    asynchook(to, from, next)
  }
}

function asynchook(to, from, next) {
  if (to.name === null) {
    router.app.$options.store.dispatch('getMenus').then((res) => {
      return next({ name: res[0].router })
    })
  }
  let name = (to.name == 'newsInfo' || to.name == 'newsInfoTwo') ? to.query.key : to.name === 'recruitInfo' ? from.name == 'home' ? 'companyNews' : from.name : to.name
  let permissionReady = router.app.$options.store.state.permission.permissionReady
  if (!permissionReady) {
    router.app.$options.store.dispatch('getMenus').then((res) => {
      router.app.$options.store.state.permission.currentRoute = name
      let n = CU.getData(name, res);
      router.app.$options.store.state.permission.subMenu = !to.name ? {} : typeof n === "object" ? n : {}
      return next()
    })
  }
  let subMenu = router.app.$options.store.state.permission.subMenu
  let menuData = router.app.$options.store.state.permission.menu
  router.app.$options.store.commit("setCurrentRoute", name);
  if (subMenu && subMenu.childList && subMenu.childList.length > 0) {
    let i = subMenu.childList.findIndex(
      (item) => item.router === name
    );
    if (i == -1) {
      let n = CU.getData(name, menuData);
      if (typeof n === "object") {
        router.app.$options.store.commit("setSubMenu", n);
      } else {
        router.app.$options.store.commit("setSubMenu", {});
      }
    }
  } else {
    let n = CU.getData(name, menuData);
    if (typeof n === "object") {
      router.app.$options.store.commit("setSubMenu", n);
    } else {
      router.app.$options.store.commit("setSubMenu", {});
    }
  }
  next()
}

function app(to, from, next) {
  if (to.name === null) {
    router.app.$options.store.dispatch('getMenus').then((res) => {
      return next({
        name: 'm' + res[0].router
      })
    })
  } else {
    let name = (to.name == 'mnewsInfo'||to.name == 'mnewsInfoTwo'||to.name == 'mnewsInfoThree') ? from.name == 'mhome' ? 'companyNews' : to.query.key.substring(1) : to.name === 'recruitInfo' ? from.name.substring(1) : to.name.substring(1)
    let permissionReady = router.app.$options.store.state.permission.permissionReady
    if (!permissionReady) {
      router.app.$options.store.dispatch('getMenus').then((res) => {
        router.app.$options.store.state.permission.currentRoute = name
        let n = CU.getData(name, res);
        router.app.$options.store.state.permission.subMenu = !to.name.substring(1) ? {} : typeof n === "object" ? n : {}
        return next()
      })
    }

    let subMenu = router.app.$options.store.state.permission.subMenu
    let menuData = router.app.$options.store.state.permission.menu
    router.app.$options.store.commit("setCurrentRoute", name);
    if (subMenu && subMenu.childList && subMenu.childList.length > 0) {
      let i = subMenu.childList.findIndex(
        (item) => item.router === name
      );
      if (i == -1) {
        let n = CU.getData(name, menuData);
        if (typeof n === "object") {
          router.app.$options.store.commit("setSubMenu", n);
        } else {
          router.app.$options.store.commit("setSubMenu", {});
        }
      }
    } else {
      let n = CU.getData(name, menuData);
      if (typeof n === "object") {
        router.app.$options.store.commit("setSubMenu", n);
      } else {
        router.app.$options.store.commit("setSubMenu", {});
      }
    }
    next()
  }
}


export default router

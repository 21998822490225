import 'babel-polyfill'
import Es6Promise from 'es6-promise'
require('es6-promise').polyfill()
Es6Promise.polyfill() //Plain Text

import Vue from "vue"
import App from './App.vue'
import router from './router'
import store from './store'
import ViewUI from "view-design"
import 'view-design/dist/styles/iview.css'
import { post } from "@common/http"
import API from "@common/http/api"
import "@assets/css/common.less"
import "@assets/js/html"
import '@/assets/iconfont/iconfont.css'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.config.productionTip = false
Vue.use(ViewUI)
Vue.use(Viewer)
Viewer.setDefaults({
  Options: { "inline": true, "button": true, "navbar": true, "title": true, "toolbar": true, "tooltip": true, "movable": true, "zoomable": true, "rotatable": true, "scalable": true, "transition": true, "fullscreen": true, "keyboard": true, "url": "data-source" }
})

Vue.prototype.$post = post
Vue.prototype.$api = API

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

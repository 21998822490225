const app = {
  state: {
    router: [],
    honorShowView: false
  },
  mutations: {
    setHonorShowView(state, honorShowView) {
      state.honorShowView = honorShowView
    }
  }
}

export default app
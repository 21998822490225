const routes = [{
    path: '/main',
    name: 'main',
    beforeEnter(to, from, next) {
      if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
        next({
          name: "m" + to.name
        })
        return;
      }
      next()
    },
    component: () => import('../views/main'),
    children: [{
        path: '/home',
        name: 'home',
        component: () => import('../views/home')
      },
      //联系我们
      {
        path: '/contactUs',
        name: 'contactUs',
        component: () => import('../views/contactUs')
      },
      //招标信息
      {
        path: '/bidding',
        name: 'bidding',
        component: () => import('../views/bidding')
      },
      {
        path: '/biddingInfo',
        name: 'biddingInfo',
        component: () => import('../views/biddingInfo')
      },
      //在线商城
      {
        path: '/onlineShopping',
        name: 'onlineShopping',
        component: () => import('../views/onlineShopping')
      },
    ]
  },
  {
    path: '/subMain',
    name: 'subMain',
    beforeEnter(to, from, next) {
      if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
        next({
          name: "m" + to.name
        })
        return;

      }
      next()
    },
    component: () => import('../views/main/subMain'),
    children: [{
        //走进农神
        path: '/groupIntroduction',
        name: 'groupIntroduction',
        component: () => import('../views/saturnEntered/groupIntroduction')
      },
      {
        path: '/coreTeam',
        name: 'coreTeam',
        component: () => import('../views/saturnEntered/coreTeam')
      },
      {
        path: '/corporateCulture',
        name: 'corporateCulture',
        component: () => import('../views/saturnEntered/corporateCulture')
      },
      {
        path: '/industrialStructure',
        name: 'industrialStructure',
        component: () => import('../views/saturnEntered/industrialStructure')
      },
      //公司内部架构图
      {
        path: '/74',
        name: 'industrialStructure',
        component: () => import('../views/saturnEntered/industrialStructure')
      },
      {
        path: '/developmentGoals',
        name: 'developmentGoals',
        component: () => import('../views/saturnEntered/developmentGoals')
      },
      {
        path: '/leadershipCare',
        name: 'leadershipCare',
        component: () => import('../views/saturnEntered/leadershipCare')
      },
      //科研创新
      {
        path: '/scienceResult',
        name: 'scienceResult',
        component: () => import('../views/scientific/scienceResult')
      },
      //集团成员 
      {
        path: '/biomedicine',
        name: 'biomedicine',
        component: () => import('../views/groupMembers/biomedicine')
      },
      {
        path: '/bioAgriculture',
        name: 'bioAgriculture',
        component: () => import('../views/groupMembers/bioAgriculture')
      },
      {
        path: '/testingCertification',
        name: 'testingCertification',
        component: () => import('../views/groupMembers/testingCertification')
      },
      {
        path: '/organicFood',
        name: 'organicFood',
        component: () => import('../views/groupMembers/organicFood')
      },
      //品牌创新
      {
        path: '/brendBioAgriculture',
        name: 'brendBioAgriculture',
        component: () => import('../views/brandProducts/brendBioAgriculture')
      },
      {
        path: '/brendBiomedicine',
        name: 'brendBiomedicine',
        component: () => import('../views/brandProducts/brendBiomedicine')
      },
      {
        path: '/brendOrganicFood',
        name: 'brendOrganicFood',
        component: () => import('../views/brandProducts/brendOrganicFood')
      },
      {
        path: '/brendTestingCertification',
        name: 'brendTestingCertification',
        component: () => import('../views/brandProducts/brendTestingCertification')
      },
      //新闻资讯
      {
        path: '/companyNews',
        name: 'companyNews',
        component: () => import('../views/newsInfomation/companyNews')
      },
      {
        path: '/industryNews',
        name: 'industryNews',
        component: () => import('../views/newsInfomation/industryDynamic')
      },
      {
        path: '/developmentHistory',
        name: 'developmentHistory',
        component: () => import('../views/saturnEntered/developmentHistory')
      },
      {
        path: '/honorQualification',
        name: 'honorQualification',
        component: () => import('../views/saturnEntered/honorQualification')
      },
      {
        path: '/newsInfo',
        name: 'newsInfo',
        component: () => import('../views/newsInfomation/newsInfo')
      },
      {
        path: '/newsInfoTwo',
        name: 'newsInfoTwo',
        component: () => import('../views/newsInfomation/newsInfo/indexTwo')
      },
      //技术支撑
      {
        path: '/AOAS',
        name: 'AOAS',
        component: () => import('../views/technicalSupport/AOAS')
      },
      {
        path: '/agriculturalUniversity',
        name: 'agriculturalUniversity',
        component: () => import('../views/technicalSupport/agriculturalUniversity')
      },
      {
        path: '/godOfAgriculture',
        name: 'godOfAgriculture',
        component: () => import('../views/technicalSupport/godOfAgriculture')
      },
      {
        path: '/southwestUniversity',
        name: 'southwestUniversity',
        component: () => import('../views/technicalSupport/southwestUniversity')
      },
      //商务服务
      {
        path: '/customerCase',
        name: 'customerCase',
        component: () => import('../views/businessServices/customerCase')
      },
      {
        path: '/InviteInvestment',
        name: 'InviteInvestment',
        component: () => import('../views/businessServices/InviteInvestment')
      },
      //人力资源
      {
        path: '/staffDevelopment',
        name: 'staffDevelopment',
        component: () => import('../views/staff/staffDevelopment')
      },
      {
        path: '/staffActivities',
        name: 'staffActivities',
        component: () => import('../views/staff/staffActivities')
      },
      {
        path: '/serviceNetwork',
        name: 'serviceNetwork',
        component: () => import('../views/businessServices/serviceNetwork')
      },
      {
        path: '/serviceCommitment',
        name: 'serviceCommitment',
        component: () => import('../views/businessServices/serviceCommitment')
      },
      {
        path: '/talentRecruitment',
        name: 'talentRecruitment',
        component: () => import('../views/staff/talentRecruitment')
      },
      {
        path: '/recruitInfo',
        name: 'recruitInfo',
        beforeEnter(to, from, next) {
          if (!to.params.jobId) {
            next({
              name: 'talentRecruitment'
            })
          } else {
            next()
          }
        },
        component: () => import('../views/staff/talentRecruitment/recruitInfo')
      },
    ]
  },
  {
    path: '/mmain',
    name: 'mmain',
    component: () => import('../views/mobile/main'),
    children: [{
      path: '/mhome',
      name: 'mhome',
      beforeEnter(to, from, next) {
        if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
          next()
          return;
        } else {
          next({
            name: "home"
          })
          return;
        }
      },
      meta: {
        isMobile: true,
      },
      component: () => import('../views/mobile/home')
    }, ]
  },
  {
    path: '/msubMain',
    name: 'msubMain',
    beforeEnter(to, from, next) {
      if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
        next()
        return;
      } else {
        next({
          name: "home"
        })
        return;
      }
    },
    meta: {
      isMobile: true,
    },
    component: () => import('../views/mobile/main/subMain'),
    children: [
      //走进农神
      {
        path: '/mgroupIntroduction',
        name: 'mgroupIntroduction',
        component: () => import('../views/mobile/article')
      },
      {
        path: '/mcoreTeam',
        name: 'mcoreTeam',
        component: () => import('../views/mobile/coreTeam')
      },
      {
        path: '/mcorporateCulture',
        name: 'mcorporateCulture',
        component: () => import('../views/mobile/article')
      },
      {
        path: '/mindustrialStructure',
        name: 'mindustrialStructure',
        component: () => import('../views/mobile/article')
      },
      {
        path: '/mdevelopmentGoals',
        name: 'mdevelopmentGoals',
        component: () => import('../views/mobile/article')
      },
      //发展历程
      {
        path: '/mdevelopmentHistory',
        name: 'mdevelopmentHistory',
        component: () => import('../views/mobile/developmentHistory')
      },
      {
        path: '/mhonorQualification',
        name: 'mhonorQualification',
        component: () => import('../views/mobile/honorQualification')
      },
      //领导关怀
      {
        path: '/mleadershipCare',
        name: 'mleadershipCare',
        component: () => import('../views/mobile/companyDetail')
      },
      //科研成果
      {
        path: '/mscienceResult',
        name: 'mscienceResult',
        component: () => import('../views/mobile/scienceResult')
      },
      //集团成员
      {
        path: '/mbiomedicine',
        name: 'mbiomedicine',
        component: () => import('../views/mobile/companyDetail')
      },
      {
        path: '/mbioAgriculture',
        name: 'mbioAgriculture',
        component: () => import('../views/mobile/companyDetail/index_new')
      },
      {
        path: '/mtestingCertification',
        name: 'mtestingCertification',
        component: () => import('../views/mobile/companyDetail')
      },
      {
        path: '/morganicFood',
        name: 'morganicFood',
        component: () => import('../views/mobile/companyDetail')
      },
      //品牌产品
      {
        path: '/mbrendBioAgriculture',
        name: 'mbrendBioAgriculture',
        component: () => import('../views/mobile/scienceResult')
      },
      {
        path: '/mbrendBiomedicine',
        name: 'mbrendBiomedicine',
        component: () => import('../views/mobile/scienceResult')
      },
      {
        path: '/mbrendOrganicFood',
        name: 'mbrendOrganicFood',
        component: () => import('../views/mobile/scienceResult')
      },
      {
        path: '/mbrendTestingCertification',
        name: 'mbrendTestingCertification',
        component: () => import('../views/mobile/scienceResult')
      },
      //新闻资讯
      {
        path: '/mcompanyNews',
        name: 'mcompanyNews',
        component: () => import('../views/mobile/companyNews')
      },
      {
        path: '/mindustryNews',
        name: 'mindustryNews',
        component: () => import('../views/mobile/companyNews')
      },
      {
        path: '/mnewsInfo',
        name: 'mnewsInfo',
        component: () => import('../views/mobile/newsInfo')
      },
      {
        path: '/mnewsInfoTwo',
        name: 'mnewsInfoTwo',
        component: () => import('../views/mobile/newsInfo/indexTwo')
      },
      {
        path: '/mnewsInfoThree',
        name: 'mnewsInfoThree',
        component: () => import('../views/mobile/newsInfo/indexThree')
      },
      //技术支撑
      {
        path: '/mAOAS',
        name: 'mAOAS',
        component: () => import('../views/mobile/article')
      },
      {
        path: '/magriculturalUniversity',
        name: 'magriculturalUniversity',
        component: () => import('../views/mobile/article')
      },
      {
        path: '/mgodOfAgriculture',
        name: 'mgodOfAgriculture',
        component: () => import('../views/mobile/article')
      },
      {
        path: '/msouthwestUniversity',
        name: 'msouthwestUniversity',
        component: () => import('../views/mobile/article')
      },
      //商务服务
      {
        path: '/mserviceNetwork',
        name: 'mserviceNetwork',
        component: () => import('../views/mobile/serviceNetwork')
      },
      {
        path: '/mserviceCommitment',
        name: 'mserviceCommitment',
        component: () => import('../views/mobile/serviceCommitment')
      },
      {
        path: '/mcustomerCase',
        name: 'mcustomerCase',
        component: () => import('../views/mobile/article')
      },
      {
        path: '/mInviteInvestment',
        name: 'mInviteInvestment',
        component: () => import('../views/mobile/InviteInvestment')
      },
      //人力资源
      {
        path: '/mtalentRecruitment',
        name: 'mtalentRecruitment',
        component: () => import('../views/mobile/talentRecruitment')
      },
      {
        path: '/mstaffDevelopment',
        name: 'mstaffDevelopment',
        component: () => import('../views/mobile/article')
      },
      {
        path: '/mstaffActivities',
        name: 'mstaffActivities',
        component: () => import('../views/mobile/article')
      },
      //联系我们
      {
        path: '/mcontactUs',
        name: 'mcontactUs',
        component: () => import('../views/mobile/contactUs')
      },
      //招标信息
      {
        path: '/mbidding',
        name: 'mbidding',
        component: () => import('../views/mobile/bidding')
      },
      {
        path: '/mbiddingInfo',
        name: 'mbiddingInfo',
        component: () => import('../views/mobile/biddingInfo')
      },
      //在线商城
      {
        path: '/monlineShopping',
        name: 'monlineShopping',
        component: () => import('../views/mobile/onlineShopping')
      }
    ],
  }
]
export default routes
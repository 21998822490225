export const baseUrl = {
  URL: process.env.NODE_ENV == 'development' ?
  'https://www.nongshen.cn/openapi/v2':
  'https://www.nongshen.cn/openapi/v2'
    // 'http://127.0.0.1':
    // 'http://127.0.0.1'
}
const API = {
  // 上传文件
  UPLOAD: {
    // 文件上传
    LOGGED_IN: 'fileup',
    // 图片上传
    IMAGE: 'fileupload/upload',
    // 限制大小上传
    UPLOAD_LIMITE: 'fileupload/filebywh',
  },
  MENU: {
    TREE_LIST: 'websitemenu/listtree',
    FOOTER_MENU: 'websitemenu/menulisttree',
  },
  //新闻资讯
  NEWS_INFO: {
    VIEW: 'newsview',
    LIST: 'websitenews/list/companyNews'
  },
  //岗位信息
  RECRUIT: {
    VIEW: 'websitejob/view'
  },
  //简历投递
  RESUME: {
    SEND: 'websitepostcv/send'
  },
  //留言
  SEND_MESSAGE: 'leavemessage/add',
  //招标投标
  BIDDING: {
    LIST: 'biddingInfo/list',
    VIEW: 'biddingInfo/view'
  },
  IMG_MANAGE: {
    LIST: 'adv/baselist'
  }
}
export default API
import axios from "axios"
import API, { baseUrl } from "./api"
import { LoadingBar, Message } from "view-design"
import { companyNo } from "@common/constants"

let instance = axios.create({
  baseURL: baseUrl.URL,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  },
  timeout: 30000,
})

instance.interceptors.request.use(config => {
  if (!config.data) {
    config.data = {};
  }
  if (!config.data.companyNo) {
    config.data.companyNo = companyNo
  }
  for (let key in config.data) {
    if (config.data[key] === "") {
      delete config.data[key];
    }
  }
  LoadingBar.start();
  return config;
})

instance.interceptors.response.use(response => {
  LoadingBar.finish()
  if (response.data.code == '200') {
    return response.data.data;
  };

  Message.error({ content: '网络繁忙，请重试', duration: 1.5 })
  return Promise.reject(response.data)
}, e => {
  LoadingBar.error()
  return Promise.reject(e)
})

export const post = instance.post
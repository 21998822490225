import { post } from "../common/http"
import API from "../common/http/api"
import CU from "../common/util"
import router from "../router"
const permission = {
  state: {
    permissionReady: false,
    menu: [],
    subMenu: {},
    currentRoute: '',
  },
  mutations: {
    setMenu(state, menu) {
      state.permissionReady = true
      state.menu = menu
    },
    setSubMenu(state, data) {
      if (!data) return
      state.subMenu = data
    },
    setCurrentRoute(state, router) {
      if (!router) return
      state.currentRoute = router
    }
  },
  actions: {
    getMenus({ commit }) {
      return new Promise((resolve, reject) => {
        post(API.MENU.TREE_LIST).then(res => {
          let menu = CU.dealWithMenu(res)
          commit('setMenu', menu)
          resolve(menu)
          // router.push({name: menu[0].router})
        }).catch(e => {
          reject(e)
        })
      })
    },
  }
}

export default permission